import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/home";
import Navbar from "./components/Navbar/navbar";
import PropertyGrid from "./components/PropertyGrid/propertyGrid";
import Footer from "./components/Footer/footer";
import AboutUs from "./components/AboutUs/aboutUs";
import Header from "./components/Header/header";
import Maintenance from "./components/Maintenance/maintenance";
// import { ScrollToTop } from "react-scroll-to-top";

function App() {
  const [isLoggedIn, setLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    if (token !== null) {
      fetch("https://msws-alex.herokuapp.com/authenticate", {
        method: "POST",
        headers: {
          // prettier-ignore
          "Authorization": `Bearer ${token}`,
        },
      }).then((resp) => {
        if (resp.ok) {
          setLoggedIn(true);
        } else {
          localStorage.removeItem("token");
        }
      });
    }
  }, []);

  function handleLogIn() {
    setLoggedIn(!isLoggedIn);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Navbar handleLogIn={handleLogIn} isLoggedIn={isLoggedIn} />
        <Routes>
          <Route index element={<Home />} />
          <Route
            path="properties"
            element={<PropertyGrid isLoggedIn={isLoggedIn} />}
          />
          <Route path="about" element={<AboutUs />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
