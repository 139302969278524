import { isMobile } from "react-device-detect";
import config from "../config.json";

export default function Header() {
  return (
    <header className="section has-background-link py-3 has-text-white">
      <div className="container">
        <div className="columns">
          {isMobile ? (
            <div className="column">
              <span className="icon mr-1">
                <i className="fas fa-phone"></i>
              </span>
              {config.phone}
              <br />
              <span className="icon mr-1">
                <i className="fas fa-envelope"></i>
              </span>
              {config.email}
            </div>
          ) : (
            <>
              <div className="column">
                <span className="icon mr-1">
                  <i className="fas fa-phone"></i>
                </span>
                {config.phone}
              </div>
              <div className="column">
                <span className="icon mr-1">
                  <i className="fas fa-envelope"></i>
                </span>
                {config.email}
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
