import React from "react";
import "bulma/css/bulma.min.css";
import { Link } from "react-router-dom";
import PostPropertyForm from "../PropertyForm/PostPropertyForm";
import Authentication from "../Authentication/Authentication";

export default function Navbar({ handleLogIn, isLoggedIn }) {
  const [burgerMenu, toggleBurgerMenu] = React.useState(false);
  const [postModal, togglePostModel] = React.useState(false);
  const [authModal, toggleAuthModal] = React.useState(false);
  function handleBurgerButton() {
    toggleBurgerMenu(!burgerMenu);
  }
  function handleTogglePostModal() {
    togglePostModel(!postModal);
  }
  function handleAuthModal() {
    console.log("click");
    toggleAuthModal(!authModal);
  }
  function handleSignout() {
    localStorage.removeItem("token");
    window.location.reload();
  }

  return (
    <>
      <nav
        className="navbar is-white px-3"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item m-2" href="/">
            <img src={require("../../files/MackaySinclairWSLogo-09.png")} />
          </a>

          <a
            role="button"
            onClick={handleBurgerButton}
            className={burgerMenu ? "navbar-burger is-active" : "navbar-burger"}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={burgerMenu ? "navbar-menu is-active" : "navbar-menu"}
        >
          <div className="navbar-start">
            <Link
              className="navbar-item m-2 capitalized"
              to=""
              onClick={handleBurgerButton}
            >
              Home
            </Link>
            <Link
              className="navbar-item m-2 capitalized"
              to="properties"
              onClick={handleBurgerButton}
            >
              Properties
            </Link>
            <Link
              className="navbar-item m-2 About Us capitalized"
              to="about"
              onClick={handleBurgerButton}
            >
              About Us
            </Link>

            {/* <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link m-2 capitalized">More</a>

            <div className="navbar-dropdown">
              <a className="navbar-item capitalized">About</a>
              <a className="navbar-item capitalized">Jobs</a>
              <a className="navbar-item capitalized">Contact</a>
              <hr className="navbar-divider" />
              <a className="navbar-item capitalized">Report an issue</a>
            </div>
          </div> */}
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                {isLoggedIn ? (
                  <a
                    className="button is-primary"
                    onClick={handleTogglePostModal}
                  >
                    <strong>Post Listing</strong>
                  </a>
                ) : // <a className="button is-info">
                //   <strong>Contact us</strong>
                // </a>
                null}
                {!isLoggedIn ? (
                  <a onClick={handleAuthModal} className="button is-light">
                    login
                  </a>
                ) : (
                  <a onClick={handleSignout} className="button is-light">
                    Logout
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <PostPropertyForm
        handleTogglePostModal={handleTogglePostModal}
        postModal={postModal}
      />
      <Authentication handleAuthModal={handleAuthModal} authModal={authModal} />
    </>
  );
}
