import React from "react";
import AnimatedHero from "../AnimatedHero/AnimatedHero";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

function Home() {
  // const [homeTabStatus, setHomeTabStatus] = React.useState([
  //   true,
  //   false,
  //   false,
  // ]);

  // function handleHomeTab(e) {
  //   console.log(e.target.id);
  //   if (e.target.id === "properties") {
  //     setHomeTabStatus([true, false, false]);
  //   } else if (e.target.id === "about") {
  //     setHomeTabStatus([false, true, false]);
  //   } else if (e.target.id === "contact") {
  //     setHomeTabStatus([false, false, true]);
  //   }
  // }

  return (
    // <section className="hero is-info is-large">
    //   <div className="hero-head">
    //     <nav className="navbar">
    //       <div className="container">
    //         <div className="navbar-brand">
    //           <a className="navbar-item">
    //             <img
    //               src="https://bulma.io/images/bulma-type-white.png"
    //               alt="Logo"
    //             />
    //           </a>
    //           <span className="navbar-burger" data-target="navbarMenuHeroB">
    //             <span></span>
    //             <span></span>
    //             <span></span>
    //           </span>
    //         </div>
    //         <div id="navbarMenuHeroB" className="navbar-menu">
    //           <div className="navbar-end">
    //             <a className="navbar-item is-active">Home</a>
    //             <a className="navbar-item">Examples</a>
    //             <a className="navbar-item">Documentation</a>
    //             <span className="navbar-item">
    //               <a className="button is-info is-inverted">
    //                 <span className="icon">
    //                   <i className="fab fa-github"></i>
    //                 </span>
    //                 <span>Download</span>
    //               </a>
    //             </span>
    //           </div>
    //         </div>
    //       </div>
    //     </nav>
    //   </div>

    //   <div className="hero-body">
    //     <div className="container has-text-centered">
    //       <p className="title">Title</p>

    //       <p className="subtitle">Subtitle</p>
    //     </div>
    //   </div>

    //   <div className="hero-foot">
    //     <nav className="tabs is-boxed is-fullwidth">
    //       <div className="container">
    //         <ul>
    //           <li className={homeTabStatus[0] ? "is-active" : ""}>
    //             <a id="properties" onClick={handleHomeTab}>
    //               Property Search
    //             </a>
    //           </li>
    //           <li className={homeTabStatus[1] ? "is-active" : ""}>
    //             <a id="about" onClick={handleHomeTab}>
    //               About Us
    //             </a>
    //           </li>
    //           <li className={homeTabStatus[2] ? "is-active" : ""}>
    //             <a onClick={handleHomeTab} id="contact">
    //               Contact
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </nav>
    //   </div>
    // </section>
    <>
      <section id="main-hero" className="hero is-link is-halfheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column"></div>
              <div className="column is-two-thirds-tablet is-three-quarters-desktop">
                <div className="has-text-left">
                  <p className={isMobile ? "title" : "title is-2"}>
                    Mackay Sinclair W.S.
                  </p>
                  <p className={isMobile ? "subtitle" : "subtitle is-5"}>
                    Find your dream property today!
                  </p>
                  <Link to="/properties">
                    <button className="button is-info">
                      <span className="icon is-small">
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </span>
                      <strong>Property Search</strong>
                    </button>
                  </Link>
                </div>
              </div>

              <div className="column"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-medium">
        <div className="container">
          <div className="has-text-centered">
            <h1 className="title" id="home-card-title">
              Solicitors and Estate Agents Edinburgh
            </h1>
          </div>
        </div>
      </section>
      <section className="section is-medium mt-0 pt-0">
        <div
          className={
            isMobile
              ? "columns is-multiline mx-3"
              : "columns is-multiline mx-6 px-6"
          }
        >
          <div className="column is-one-third">
            <div className="card" id="homepage-card">
              <figure className="image is-4by3">
                <img
                  src="https://images.unsplash.com/flagged/photo-1564767609342-620cb19b2357?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1373&q=80"
                  alt="Placeholder image"
                  id="homepage-card-image"
                />
              </figure>
              <div className="card-content">
                <p id="home-card-title" className="title is-5">
                  Estate Agency and Conveyancing
                </p>
                <p>
                  Our Estate Agency and Conveyancing service is carried out by
                  our highly experienced team in a friendly and professional
                  manner, with the added benefit of their knowledge in the local
                  property market.
                </p>
              </div>
              <footer className="card-footer">
                <a href="About" className="card-footer-item">
                  Learn More
                </a>
              </footer>
            </div>
          </div>

          <div className="column">
            <div className="card" id="homepage-card">
              <div className="card-image">
                <figure className="image is-4by3">
                  <img
                    src="https://images.unsplash.com/photo-1455390582262-044cdead277a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1373&q=80"
                    alt="Placeholder image"
                    id="homepage-card-image"
                  />
                </figure>
              </div>
              <div className="card-content">
                <p id="home-card-title" className="title is-5">
                  Wills and Executries
                </p>
                <div className="content">
                  At Mackay Sinclair WS we understand that writing a Will is a
                  very personal and sometimes challenging experience and our
                  team is here to help every step of the way handling each
                  client with sensitivity and professionalism.
                </div>
              </div>
              <footer className="card-footer">
                <a href="About" className="card-footer-item">
                  Learn More
                </a>
              </footer>
            </div>
          </div>

          <div className="column">
            <div className="card" id="homepage-card">
              <figure className="image is-4by3">
                <img
                  src="https://images.unsplash.com/photo-1579208575657-c595a05383b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  alt="Placeholder image"
                  id="homepage-card-image"
                />
              </figure>
              <div className="card-content">
                <p id="home-card-title" className="title is-5">
                  Welfare and Powers of Attorney
                </p>
                <div className="content">
                  Welfare and Continuing Powers of Attorney allow people you
                  nominate to act within your best interest and on your behalf
                  when you are unable to. This can be due to infirmity and/or
                  old age.
                </div>
              </div>

              <footer className="card-footer">
                <a href="About" className="card-footer-item">
                  Learn More
                </a>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
