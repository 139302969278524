import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Authentication({ handleAuthModal, authModal }) {
  const [form, setForm] = useState({
    username: "",
    password: "",
    passwordConfirmation: "",
    pin: "",
  });
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });
  const [blankFields, setBlankFields] = React.useState(false);
  const [registerError, setRegisterError] = React.useState(false);
  const [postSubmit, setPostSubmit] = React.useState(false);
  const [registerSuccess, setRegisterSuccess] = React.useState(false);
  const [hasAccount, setHasAccount] = React.useState(false);
  const [loginBlankFields, setLoginBlankFields] = React.useState(false);
  const [attemptedSubmitLogin, setAttemptedSubmitLogin] = React.useState(false);
  const [postSubmitLogin, setPostSubmitLogin] = React.useState(false);
  const [warningButton, setWarningButton] = React.useState(false);

  function handleChange(e) {
    const { id, value } = e.target;

    setForm({
      ...form,
      [id]: value,
    });
  }

  function handleLoginChange(e) {
    const { id, value } = e.target;

    setLoginForm({
      ...loginForm,
      [id]: value,
    });
  }

  function handleModalSwitch() {
    console.log("test");
    setHasAccount(!hasAccount);
  }

  function postLoginSuccess() {
    setTimeout(function () {
      setWarningButton(false);
    }, 2000);

    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }

  function handleSubmitLogin(e) {
    e.preventDefault();
    if (loginForm.username && loginForm.password) {
      setPostSubmitLogin(true);
      setAttemptedSubmitLogin(false);
      setLoginBlankFields(false);
      setWarningButton(true);
      fetch("https://msws-alex.herokuapp.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: loginForm.username,
          password: loginForm.password,
        }),
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((Data) => {
              localStorage.removeItem("token");
              localStorage.setItem("token", JSON.stringify(Data.accessToken));
              postLoginSuccess();
            });
            // .then(() => setSignedIn(true))
            // .then(() => localStorage.setItem("signedIn", true));
          } else {
            setWarningButton(false);
            setPostSubmitLogin(false);
            console.log(resp.status);
            setAttemptedSubmitLogin(true);
          }
        })
        .catch((err) => {
          toast.error("Failed to login", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            toastId: "failure2",
          });
          setPostSubmitLogin(false);
        });
    } else {
      setLoginBlankFields(true);
    }
  }

  function handleSubmit(e) {
    if (
      form.username &&
      form.password &&
      form.pin &&
      form.password === form.passwordConfirmation
    ) {
      setPostSubmit(true);
      setRegisterSuccess(false);
      setRegisterError(false);
      fetch("https://msws-alex.herokuapp.com/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: form.username,
          password: form.password,
          pin: form.pin,
        }),
      })
        .then((resp) => {
          if (resp.status !== 200) {
            setPostSubmit(false);
            setRegisterError(true);
            throw new Error();
          } else {
            setPostSubmit(false);
            setRegisterSuccess(true);
            return resp.json();
          }
        })
        .catch((err) => {
          toast.error("Failed to register", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            toastId: "failure2",
          });
          setPostSubmit(false);
        });
    } else {
      setBlankFields(true);
    }
  }

  return hasAccount ? (
    <div className={authModal ? "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <div className="container has-text-left">
            <p className="modal-card-title is-left">Staff Portal - Register</p>
          </div>

          <button
            onClick={handleAuthModal}
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Username</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={
                  blankFields && !form.username ? "input is-danger" : "input"
                }
                type="text"
                id="username"
                placeholder="Username"
                value={form.username}
                onChange={handleChange}
              />

              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
              {blankFields && !form.username ? (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              ) : null}
            </div>
            {blankFields && !form.username ? (
              <p className="help is-danger">Username can't be blank!</p>
            ) : null}
          </div>

          <div className="field">
            <label className="label">Password</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={
                  blankFields && !form.password ? "input is-danger" : "input"
                }
                type="password"
                placeholder="Password"
                id="password"
                value={form.password}
                onChange={handleChange}
              />

              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              {blankFields && !form.password ? (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              ) : null}
            </div>
            {blankFields && !form.password ? (
              <p className="help is-danger">Password can't be blank!</p>
            ) : null}
            {blankFields && form.password !== form.passwordConfirmation ? (
              <p className="help is-danger">Passwords don't match!</p>
            ) : null}
          </div>

          <div className="field">
            <label className="label">Confirm Password</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={
                  blankFields && !form.passwordConfirmation
                    ? "input is-danger"
                    : "input"
                }
                type="password"
                placeholder="Confirm Password"
                id="passwordConfirmation"
                value={form.passwordConfirmation}
                onChange={handleChange}
              />

              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              {blankFields && !form.passwordConfirmation ? (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              ) : null}
            </div>
            {blankFields && !form.passwordConfirmation ? (
              <p className="help is-danger">
                Password confirmation can't be blank!
              </p>
            ) : null}
            {blankFields && form.password !== form.passwordConfirmation ? (
              <p className="help is-danger">Passwords don't match!</p>
            ) : null}
          </div>

          <div className="field">
            <label className="label">Pin</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={
                  blankFields && !form.pin ? "input is-danger" : "input"
                }
                type="password"
                placeholder="Pin"
                id="pin"
                value={form.pin}
                onChange={handleChange}
              />

              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              {blankFields && !form.pin ? (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              ) : null}
            </div>
            {blankFields && !form.pin ? (
              <p className="help is-danger">Pin can't be blank!</p>
            ) : null}
          </div>
        </section>
        <section className="modal-card-body">
          <p className="help">
            Already have an account?{" "}
            <strong>
              <a onClick={handleModalSwitch}>Login</a>
            </strong>
          </p>
        </section>

        <footer className="modal-card-foot">
          <button
            onClick={handleSubmit}
            className={
              postSubmit ? "button is-success is-loading" : "button is-success"
            }
          >
            Register
          </button>
          <button onClick={handleAuthModal} className="button">
            Cancel
          </button>

          {registerError ? (
            <p className="help is-danger">Error, invalid credentials</p>
          ) : null}
          {registerSuccess ? (
            <p className="help is-success">
              Success, new user created! Now login.
            </p>
          ) : null}
        </footer>
      </div>
    </div>
  ) : (
    <div className={authModal ? "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <div className="container has-text-left">
            <p className="modal-card-title is-left">Staff Portal - Login</p>
          </div>

          <button
            onClick={handleAuthModal}
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Username</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={
                  loginBlankFields && !loginForm.username
                    ? "input is-danger"
                    : "input"
                }
                type="text"
                id="username"
                placeholder="Username"
                value={loginForm.username}
                onChange={handleLoginChange}
              />

              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
              {loginBlankFields && !loginForm.username ? (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              ) : null}
            </div>
            {loginBlankFields && !loginForm.username ? (
              <p className="help is-danger">Username can't be blank!</p>
            ) : null}
          </div>

          <div className="field">
            <label className="label">Password</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className={
                  loginBlankFields && !loginForm.password
                    ? "input is-danger"
                    : "input"
                }
                type="password"
                placeholder="Password"
                id="password"
                value={loginForm.password}
                onChange={handleLoginChange}
              />

              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              {loginBlankFields && !loginForm.password ? (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              ) : null}
            </div>
            {loginBlankFields && !loginForm.password ? (
              <p className="help is-danger">Password can't be blank!</p>
            ) : null}
          </div>
        </section>
        <section className="modal-card-body">
          <p className="help">
            Don't have an account?{" "}
            <strong>
              <a onClick={handleModalSwitch}>Register</a>
            </strong>
          </p>
        </section>
        <footer className="modal-card-foot">
          <button
            onClick={handleSubmitLogin}
            className={
              postSubmitLogin
                ? warningButton
                  ? "button is-warning is-loading"
                  : "button is-success is-loading"
                : "button is-success"
            }
          >
            Login
          </button>
          <button onClick={handleAuthModal} className="button">
            Cancel
          </button>
          {attemptedSubmitLogin ? (
            <p className="help is-danger">Error, invalid credentials</p>
          ) : null}
          {postSubmitLogin ? (
            <p className="help">Authenticating with AlexAuth</p>
          ) : null}
        </footer>
      </div>
    </div>
  );
}
