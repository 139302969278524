import React, { useState } from "react";
import SimpleFileUpload from "react-simple-file-upload";

export default function PostPropertyForm({ postModal, handleTogglePostModal }) {
  const [form, setForm] = useState({
    title: "",
    headline: "",
    price: "",
    description: "",
    beds: "",
    baths: "",
    postPicture: "",
    link: "",
  });

  const [validPost, setValidPost] = React.useState(false);

  function handleChange(e) {
    const { id, value } = e.target;

    setForm({
      ...form,
      [id]: value,
    });
  }

  function handleFileUpload(url) {
    setForm({
      ...form,
      postPicture: url,
    });
  }

  // ! Start of post fetch

  function handleSubmit(e) {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("token"));

    fetch("https://msws-alex.herokuapp.com/addproperty", {
      method: "POST",
      headers: {
        // prettier-ignore
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: form.title,
        headline: form.headline,
        price: form.price,
        description: form.description,
        beds: form.beds,
        baths: form.baths,
        postPicture: form.postPicture,
        link: form.link,
      }),
    })
      .then((resp) => {
        if (resp.status !== 200) {
          throw new Error();
        } else {
          setValidPost(true);
          window.location.reload();
          return resp.json();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // ! End of post fetch

  return (
    <>
      <div className={postModal ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">New Listing</p>
            <button
              className="delete"
              aria-label="close"
              onClick={handleTogglePostModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <nav className="panel">
              <div className="panel-block is-active">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    id="title"
                    type="text"
                    disabled={validPost ? true : false}
                    value={form.title}
                    onChange={handleChange}
                    placeholder="title"
                  />
                  <span className="icon is-left">
                    <i className="fa-solid fa-heading" aria-hidden="true"></i>
                  </span>
                </p>
              </div>
              <div className="panel-block is-active">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    id="headline"
                    type="text"
                    disabled={validPost ? true : false}
                    value={form.headline}
                    onChange={handleChange}
                    placeholder="headline"
                  />
                  <span className="icon is-left">
                    <i
                      className="fa-solid fa-align-center"
                      aria-hidden="true"
                    ></i>
                  </span>
                </p>
              </div>
              <div className="panel-block is-active">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    id="link"
                    type="text"
                    disabled={validPost ? true : false}
                    value={form.link}
                    onChange={handleChange}
                    placeholder="link"
                  />
                  <span className="icon is-left">
                    <i className="fa-solid fa-link" aria-hidden="true"></i>
                  </span>
                </p>
              </div>
              <div className="panel-block is-active">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    id="price"
                    type="number"
                    disabled={validPost ? true : false}
                    value={form.price}
                    onChange={handleChange}
                    placeholder="price"
                  />
                  <span className="icon is-left">
                    <i
                      className="fa-solid fa-sterling-sign"
                      aria-hidden="true"
                    ></i>
                  </span>
                </p>
              </div>
              <div className="panel-block is-active">
                <p className="control">
                  <textarea
                    className="textarea"
                    rows="10"
                    id="description"
                    type="text"
                    disabled={validPost ? true : false}
                    value={form.description}
                    onChange={handleChange}
                    placeholder="description"
                  />
                </p>
              </div>
              <div className="panel-block is-active">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    id="beds"
                    type="number"
                    disabled={validPost ? true : false}
                    value={form.beds}
                    onChange={handleChange}
                    placeholder="beds"
                  />
                  <span className="icon is-left">
                    <i className="fas fa-bed" aria-hidden="true"></i>
                  </span>
                </p>
              </div>
              <div className="panel-block is-active">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    id="baths"
                    type="number"
                    disabled={validPost ? true : false}
                    value={form.baths}
                    onChange={handleChange}
                    placeholder="baths"
                  />
                  <span className="icon is-left">
                    <i className="fas fa-bath" aria-hidden="true"></i>
                  </span>
                </p>
              </div>
              <div className="panel-block">
                <SimpleFileUpload
                  apiKey="1ef8b16b728cc2fe5c8e9423af5373f2"
                  data-maxFileSize="3"
                  onSuccess={handleFileUpload}
                />
              </div>
            </nav>
          </section>
          <footer className="modal-card-foot">
            {validPost ? (
              <button className="button is-success" disabled>
                Post Successful
              </button>
            ) : (
              <button onClick={handleSubmit} className="button is-success">
                Post Listing
              </button>
            )}
            <button className="button" onClick={handleTogglePostModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}
