import React from "react";
import { Link } from "react-router-dom";
import config from "../config.json";

export default function AboutUs() {
  const [homeTabStatus, setHomeTabStatus] = React.useState([
    true,
    false,
    false,
    false,
  ]);

  function handleHomeTab(e) {
    if (e.target.id === "about") {
      setHomeTabStatus([true, false, false, false]);
    } else if (e.target.id === "wills") {
      setHomeTabStatus([false, true, false, false]);
    } else if (e.target.id === "estate") {
      setHomeTabStatus([false, false, true, false]);
    } else if (e.target.id === "welfare") {
      setHomeTabStatus([false, false, false, true]);
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  function handlePreviousClick() {
    if (homeTabStatus[3]) {
      setHomeTabStatus([false, false, true, false]);
      scrollToTop();
    } else if (homeTabStatus[2]) {
      setHomeTabStatus([false, true, false, false]);
      scrollToTop();
    } else if (homeTabStatus[1]) {
      setHomeTabStatus([true, false, false, false]);
      scrollToTop();
    }
  }

  function handleNextClick() {
    if (homeTabStatus[0]) {
      setHomeTabStatus([false, true, false, false]);
      scrollToTop();
    } else if (homeTabStatus[1]) {
      setHomeTabStatus([false, false, true, false]);
      scrollToTop();
    } else if (homeTabStatus[2]) {
      setHomeTabStatus([false, false, false, true]);
      scrollToTop();
    }
  }

  let start = true;
  let end = false;

  if (homeTabStatus[0]) {
    end = false;
    start = true;
  } else if (homeTabStatus[3]) {
    start = false;
    end = true;
  } else {
    start = false;
    end = false;
  }

  console.log(start, end);
  console.log(homeTabStatus[0]);
  return (
    <div className="verticalMinHeight">
      <section className="hero is-info is-medium" id="about-hero">
        <div className="hero-body"></div>

        <div className="hero-foot">
          <nav className="tabs is-boxed is-fullwidth">
            <div className="container">
              <ul>
                <li className={homeTabStatus[0] ? "is-active" : ""}>
                  <a id="about" onClick={handleHomeTab}>
                    About Us
                  </a>
                </li>
                <li className={homeTabStatus[1] ? "is-active" : ""}>
                  <a id="wills" onClick={handleHomeTab}>
                    Wills and Executries
                  </a>
                </li>
                <li className={homeTabStatus[2] ? "is-active" : ""}>
                  <a onClick={handleHomeTab} id="estate">
                    Estate Agency and Residential Conveyancing
                  </a>
                </li>
                <li className={homeTabStatus[3] ? "is-active" : ""}>
                  <a onClick={handleHomeTab} id="welfare">
                    Welfare and Continuing Powers of Attorney
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>

      {homeTabStatus[0] ? (
        <section
          className="section is-medium has-text-left"
          id="about-us-section"
        >
          <h1 className="title">About Us</h1>
          <h2 className="subtitle mt-3">
            <strong>
              We are an established firm based in Edinburgh and members of the
              ESPC.
            </strong>
          </h2>
          <p>
            Life doesn’t have to be complicated! Let Mackay Sinclair WS cut
            through the legal jargon and lighten your burden with our plain
            speaking, no nonsense approach. We understand that having any legal
            work undertaken can be a daunting experience, so allow our team to
            guide you through your transaction, answering any questions you may
            have along the way. Mackay Sinclair is an established Solicitors and
            Estate Agents located to the East of Edinburgh City Centre. With
            over 20 years’ experience as a sole practitioner, William Sinclair
            has built up a successful practise with particular expertise in
            Residential and Commercial Property Law and Estate Agency as well as
            dealing with Wills and Executries and other aspects of Private
            Client work. As a small firm we are dedicated to providing a high
            quality personal service to each individual client at a very
            reasonable cost. With friendly professional staff trained to deal
            with your requirements whatever your needs and circumstances, we are
            here to help!
          </p>
        </section>
      ) : null}

      {homeTabStatus[1] ? (
        <section
          className="section is-medium has-text-left"
          id="wills-and-executries-section"
        >
          <h1 className="title">Wills and Executries</h1>
          <h2 className="subtitle mt-4">
            <strong>Who requires a Will?</strong>
          </h2>
          <p>
            Making a Will is not just for senior citizens, it is important that
            we all take steps to make sure that, when the time comes, our assets
            are distributed to the people of our choice with minimal
            complication and delay. It doesn’t necessarily follow that our loved
            ones will inherit our estate if a Will has not been written. Upon
            acquiring any assets, you may wish to start thinking about drawing
            up a Will.
            <br />
            <br />
            At Mackay Sinclair WS we understand that writing a Will is a very
            personal and sometimes challenging experience and our team is here
            to help every step of the way handling each client with sensitivity
            and professionalism.
          </p>

          <h2 className="subtitle mt-4">
            <strong>What are the costs involved?</strong>
          </h2>
          <p>
            We offer a very competitive rate for the creation of a Will, which
            may vary depending on the degree of the complexity. If you are in a
            couple and wish to create a mirror Wills, we can arrange a special
            package at a discounted rate. For a more detailed quote, please call
            {` ${config.phone} `}or email{` ${config.email} `}
          </p>

          <h2 className="subtitle mt-4">
            <strong>What should I be thinking about putting in my Will?</strong>
          </h2>
          <p>
            When drawing up a Will, you should think about who you would want to
            carry out your last wishes. This person is known as an Executor. You
            may have more than one Executor for your Will.
            <br />
            <br />
            You need to also think about what assets you have, and who you wish
            them to go to. You may simply want to leave everything to one
            person, divide it between a group, or have specific items go to
            certain individuals. There is no set way to go about this, it is
            entirely dependent on what you feel is best for you.
          </p>

          <h2 className="subtitle mt-4">
            <strong>Executries</strong>
          </h2>
          <p>
            Our Executry Team are also hear to help once a loved one has passed
            away, by dealing with all aspects of winding up their estate. Our
            compassionate approach aims to take as much of the burden from off
            your shoulders as possible. The team will guide you through the
            process of the Executory, whilst updating you each step of the way,
            ensuring as smooth a transition as possible.
            <br />
            <br />
            Whether a loved one has left a Will or perhaps has passed away
            Intestate, at Mackay Sinclair we have a vast experience of the many
            different scenarios that can arise allowing us to tailor an
            appropriate approach for each client.
          </p>
        </section>
      ) : null}

      {homeTabStatus[2] ? (
        <section
          className="section is-medium has-text-left"
          id="estate-agency-section"
        >
          <h1 className="title">Estate Agency and Residential Conveyancing</h1>
          <p>
            Our Estate Agency and Conveyancing service is carried out by our
            highly experienced team in a friendly and professional manner, with
            the added benefit of their knowledge in the local property market.
            The team will guide you through the entire experience of buying and
            selling your property from start to finish. We are members of the
            ESPC.
          </p>

          <h2 className="subtitle mt-4">
            <strong>Buying</strong>
          </h2>
          <p>
            We at Mackay Sinclair strive to make your purchasing experience as
            stress free as possible. We will act for you from your very first
            Note of Interest on a property, until you have the key in your hand!
            We know that buying a property can be a complex and confusing
            process, especially for first time buyers, so our team will explain
            the steps of the process as we go along whilst answering any queries
            you have.
          </p>

          <h2 className="subtitle mt-4">
            <strong>Selling</strong>
          </h2>
          <p>
            At Mackay Sinclair we take a more personalised approach to your
            property sale, with the same team members following your transaction
            from the Estate Agency to the Conveyancing ensuring we are up to
            date with all aspects of your sale. Our team will create a sales
            package with your needs in mind, make the entire selling experience
            as smooth as possible for you.
          </p>

          <h2 className="subtitle mt-4">
            <strong>What are the cost involved?</strong>
          </h2>
          <p>
            We provide a practical and efficient cost-effective service in this
            area. Contact our team for a detailed quote by emailing
            {` ${config.email} `} or calling us on{` ${config.phone}`}. Our
            quotes will cover all aspects of you Conveyancing and/or Estate
            Agency work, from advertising dues to Land and Building Transaction
            tax.
          </p>

          <h2 className="subtitle mt-4">
            <strong>Commercial Conveyancing</strong>
          </h2>
          <p>
            As well as Residential Conveyancing William Sinclair has extensive
            experience in helping an array of business in buying/selling
            commercial premises, negotiating, preparing and executing commercial
            leases as well as drawing up Partnership Agreements.
          </p>
        </section>
      ) : null}
      {homeTabStatus[3] ? (
        <section
          className="section is-medium has-text-left"
          id="estate-agency-section"
        >
          <h1 className="title">Welfare and Continuing Powers of Attorney</h1>
          <h2 className="subtitle mt-4">
            <strong>
              What is a Welfare and Continuing Powers of Attorney?
            </strong>
          </h2>
          <p>
            Welfare and Continuing Powers of Attorney allow people you nominate
            to act within your best interest and on your behalf when you are
            unable to. This can be due to infirmity and/or old age.
          </p>

          <h2 className="subtitle mt-4">
            <strong>
              What is required to create a Welfare and Continuing Powers of
              Attorney?
            </strong>
          </h2>
          <p>
            Welfare and Continuing Powers of Attorney require you to name a
            person or persons you wish to have handling your affairs when you
            are not able to conduct them yourself. This should be someone who is
            competent enough to handle your affairs, and who you trust to do so.
          </p>

          <h2 className="subtitle mt-4">
            <strong>What are the cost involved?</strong>
          </h2>
          <p>
            A Welfare and Continuing Power of Attorney needs registered with the
            Office of the Public Guardian and we are able to advise you of the
            current level of registration dues.
            <br />
            <br />
            Please contact Mackay Sinclair today for a highly competitive quote
            for your Power of Attorney on{` ${config.phone} `}or by emailing
            {` ${config.email} `}
          </p>

          <h2 className="subtitle mt-4">
            <strong>General Powers of Attorney</strong>
          </h2>
          <p>
            If you require a Power of Attorney for only a short period, you
            should further think about the specific Powers you wish to grant and
            the duration you wish them to be active for. Mackay Sinclair can
            help guide you through this process to ensure all of your bases are
            fully covered.
          </p>
        </section>
      ) : null}
      <nav className="pagination p-5" role="navigation" aria-label="pagination">
        <a
          onClick={handlePreviousClick}
          className={
            start ? "pagination-previous is-hidden" : "pagination-previous"
          }
        >
          Previous
        </a>
        <a
          onClick={handleNextClick}
          className={end ? "pagination-next is-hidden" : "pagination-next"}
        >
          Next Page
        </a>
        <ul className="pagination-list"></ul>
      </nav>
    </div>
  );
}
