import React from "react";
import { BarLoader } from "react-spinners";
import EditPropertyForm from "../PropertyForm/EditPropertyForm";
import { css } from "@emotion/react";
import { motion } from "framer-motion";

export default function PropertyCard({
  property,
  isLoggedIn,
  handleFavourite,
  isFavourite,
}) {
  const [modal, toggleModal] = React.useState(false);
  const [deleteWarning, toggleDeleteWarning] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(true);
  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-color: #50e3c2;
  `;

  function handleImageLoad() {
    setImageLoading(false);
  }

  function handleToggleModal() {
    toggleModal(!modal);
  }

  function handleToggleDeleteWarning() {
    toggleDeleteWarning(!deleteWarning);
  }

  function deleteProperty() {
    const token = JSON.parse(localStorage.getItem("token"));

    fetch(`https://msws-alex.herokuapp.com/deleteproperty/${property.id}`, {
      method: "DELETE",
      headers: {
        // prettier-ignore
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((resp) => {
      if (resp.status === 200) {
        window.location.reload();
      } else {
        throw new Error();
      }
    });
  }

  return (
    <>
      <motion.div layout className="column is-one-quarter">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon mr-1">
                <i className="fa-solid fa-bed" aria-hidden="true"></i>
              </span>
              {property.beds}
              <span className="icon mr-1 ml-2">
                <i className="fa-solid fa-bath" aria-hidden="true"></i>
              </span>
              {property.baths}
              {isLoggedIn ? (
                <span className="icon mr-1 ml-2">
                  <i className="fa-solid fa-fingerprint" aria-hidden="true"></i>
                </span>
              ) : null}
              {isLoggedIn ? property.id : null}
            </p>

            <button
              onClick={() => {
                handleFavourite(property.id);
              }}
              className="card-header-icon"
              aria-label="more options"
            >
              <span className="icon">
                <i
                  className={
                    isFavourite ? "fa-solid fa-star" : "fa-regular fa-star"
                  }
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </header>
          <div className="card-image">
            <figure className="image is-4by3">
              {imageLoading ? <BarLoader css={override} /> : <></>}
              {
                <img
                  style={{ visibility: imageLoading ? "hidden" : "visible" }}
                  onLoad={handleImageLoad}
                  src={
                    property.postPicture
                      ? property.postPicture
                      : "https://bulma.io/images/placeholders/1280x960.png"
                  }
                  alt="Placeholder image"
                />
              }
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{property.title}</p>
                {property.status ? (
                  <p className="subtitle is-6" id="soldText">
                    SOLD
                  </p>
                ) : (
                  <p className="subtitle is-6">{property.headline}</p>
                )}
              </div>
            </div>

            <div className="content">
              {property.description}
              <br />
              <p>Posted {property.postDate}</p>
            </div>
          </div>
          <footer className="card-footer">
            {isLoggedIn ? (
              <>
                <a className="card-footer-item" onClick={handleToggleModal}>
                  Edit
                </a>
                <a
                  className="card-footer-item"
                  onClick={handleToggleDeleteWarning}
                >
                  Delete
                </a>
              </>
            ) : (
              <a
                href={property.link}
                target="_blank"
                className="card-footer-item"
              >
                More Details
              </a>
            )}
          </footer>
        </div>
      </motion.div>

      <EditPropertyForm
        property={property}
        handleToggleModal={handleToggleModal}
        modal={modal}
      />
      <div className={deleteWarning ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title"></p>
          </header>
          <section className="modal-card-body">
            Are you sure you want to delete "{property.title}"? This action is
            permanant!
          </section>
          <footer className="modal-card-foot">
            <button className="button is-danger" onClick={deleteProperty}>
              Confirm Deletion
            </button>
            <button className="button" onClick={handleToggleDeleteWarning}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}
