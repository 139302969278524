import React from "react";
import PropertyCard from "../PropertyCard/PropertyCard";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { BarLoader } from "react-spinners";
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PropertGrid({ isLoggedIn }) {
  const [properties, setProperties] = React.useState(false);
  const [beds, setBeds] = React.useState("unselected");
  const [min, setMin] = React.useState("");
  const [max, setMax] = React.useState("");
  const [favouritesOnly, setFavouritesOnly] = React.useState(false);
  const [mobileSearch, toggleMobileSearch] = React.useState(false);
  const [viewSold, toggleViewSold] = React.useState(false);
  const [favourites, setFavourites] = React.useState(
    JSON.parse(localStorage.getItem("favourites")) || []
  );
  const [failedFetch, setFailedFetch] = React.useState(false);

  function handleMobileSeachToggle() {
    toggleMobileSearch(!mobileSearch);
  }

  function notifyFailed() {
    toast.error("Failed to fetch properties", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      toastId: "failure2",
    });
  }

  function notifyFailedMobile() {
    toast.error("Failed to fetch properties", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      toastId: "failure2",
    });
  }

  React.useEffect(() => {
    localStorage.setItem("favourites", JSON.stringify(favourites));
  }, [favourites]);

  function handleFavourite(id) {
    const newFavourites = [...favourites];
    if (favourites.includes(id)) {
      const propertyIndex = favourites.indexOf(id);
      newFavourites.splice(propertyIndex, 1);
    } else {
      newFavourites.push(id);
    }
    setFavourites(newFavourites);
  }

  React.useEffect(() => {
    fetch("https://msws-alex.herokuapp.com/properties")
      .then((resp) => resp.json())
      .then((data) => {
        setProperties(data);
      })
      .catch((err) => {
        console.error(err);
        if (isMobile) {
          notifyFailedMobile();
        } else {
          notifyFailed();
        }
        setFailedFetch(true);
      });
  }, []);

  function resetSearch() {
    window.location.reload();
  }

  function handleBedroomSelect(e) {
    setBeds(e.target.value);
    filterProperties();
  }

  function handleMinSelect(e) {
    setMin(e.target.value);
  }

  function handleMaxSelect(e) {
    setMax(e.target.value);
  }

  function handleSetFavouritesOnly() {
    setFavouritesOnly(!favouritesOnly);
    filterProperties();
  }

  function handleViewSold() {
    toggleViewSold(!viewSold);
    filterProperties();
  }

  function filterProperties() {
    return properties.filter((property) => {
      let minimumPrice = 0;
      let maximumPrice = 10000000;
      let displaySoldProperties = false;

      if (min !== "") {
        minimumPrice = min;
      }
      if (max !== "") {
        maximumPrice = max;
      }

      if (beds === "unselected" && !favouritesOnly && !viewSold) {
        return (
          minimumPrice <= property.price &&
          property.price <= maximumPrice &&
          !property.status
        );
      } else if (beds !== "unselected" && !favouritesOnly && !viewSold) {
        return (
          minimumPrice <= property.price &&
          property.price <= maximumPrice &&
          !property.status &&
          property.beds >= beds
        );
      } else if (beds === "unselected" && favouritesOnly && !viewSold) {
        return (
          minimumPrice <= property.price &&
          property.price <= maximumPrice &&
          !property.status &&
          favourites.includes(property.id)
        );
      } else if (beds === "unselected" && !favouritesOnly && viewSold) {
        return minimumPrice <= property.price && property.price <= maximumPrice;
      } else if (beds !== "unselected" && favouritesOnly && !viewSold) {
        return (
          minimumPrice <= property.price &&
          property.price <= maximumPrice &&
          !property.status &&
          favourites.includes(property.id) &&
          property.beds >= beds
        );
      } else if (beds === "unselected" && favouritesOnly && viewSold) {
        return (
          minimumPrice <= property.price &&
          property.price <= maximumPrice &&
          favourites.includes(property.id)
        );
      } else if (beds !== "unselected" && !favouritesOnly && viewSold) {
        return (
          minimumPrice <= property.price &&
          property.price <= maximumPrice &&
          property.beds >= beds
        );
      } else if (beds !== "unselected" && favouritesOnly && viewSold) {
        return (
          minimumPrice <= property.price &&
          property.price <= maximumPrice &&
          property.beds >= beds &&
          favourites.includes(property.id)
        );
      }
    });
  }

  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-color: #000034;
  `;

  return (
    <div className="paddthisout">
      {!failedFetch && (
        <>
          <BrowserView>
            <div className="container mt-4 mb-6">
              <div className="field has-addons">
                <p className="control">
                  <span className="select">
                    <select className="select" onChange={handleBedroomSelect}>
                      <option value="unselected" disabled selected>
                        Bedrooms
                      </option>
                      <option value="1">1 Bed</option>
                      <option value="2">2 Beds</option>
                      <option value="3">3 Beds</option>
                      <option value="4">4 Beds</option>
                      <option value="5">5 Beds +</option>
                    </select>
                  </span>
                </p>
                <p className="control is-expanded">
                  <input
                    type="number"
                    className="input"
                    value={min}
                    onChange={handleMinSelect}
                    placeholder="Min Price"
                  />
                </p>
                <p className="control is-expanded">
                  <input
                    type="number"
                    className="input"
                    value={max}
                    onChange={handleMaxSelect}
                    placeholder="Max Price"
                  />
                </p>
                <p className="control">
                  <label className="input">
                    <span className="mr-3 ml-1">Favourites</span>
                    <input
                      onChange={handleSetFavouritesOnly}
                      id="fav-checkbox"
                      type="checkbox"
                    />
                  </label>
                </p>
                <p className="control">
                  <label className="input">
                    <span className="mr-3 ml-1">View Sold</span>
                    <input
                      onChange={handleViewSold}
                      id="fav-checkbox"
                      type="checkbox"
                    />
                  </label>
                </p>

                <p className="control">
                  <button onClick={resetSearch} className="button is-info">
                    Reset Search
                  </button>
                </p>
              </div>
            </div>
          </BrowserView>
          <MobileView>
            <div className="card mb-3">
              <header
                className="card-header"
                onClick={properties ? handleMobileSeachToggle : null}
              >
                <p className="card-header-title">Filter Search</p>
                <button className="card-header-icon" aria-label="more options">
                  <span className="icon">
                    <i
                      className={
                        !mobileSearch ? "fas fa-angle-down" : "fas fa-angle-up"
                      }
                      aria-hidden="true"
                    ></i>
                  </span>
                </button>
              </header>
              {mobileSearch ? (
                <div className="card-content">
                  <div className="content">
                    <span className="select">
                      <select className="select" onChange={handleBedroomSelect}>
                        <option value="unselected" disabled selected>
                          Bedrooms
                        </option>
                        <option value="1">1 Bed</option>
                        <option value="2">2 Beds</option>
                        <option value="3">3 Beds</option>
                        <option value="4">4 Beds</option>
                        <option value="5">5 Beds +</option>
                      </select>
                    </span>
                  </div>
                  <div className="content">
                    <label className="input">
                      <input
                        onChange={handleSetFavouritesOnly}
                        id="fav-checkbox"
                        type="checkbox"
                      />
                      <span className="ml-3">Favourites</span>
                    </label>
                  </div>
                  <div className="content">
                    <label className="input">
                      <input
                        onChange={handleViewSold}
                        id="fav-checkbox"
                        type="checkbox"
                      />
                      <span className="ml-3">View Sold</span>
                    </label>
                  </div>
                  <div className="content">
                    <input
                      className="input"
                      type="number"
                      value={min}
                      onChange={handleMinSelect}
                      placeholder="Min Price"
                    />
                  </div>
                  <div className="content">
                    <input
                      className="input"
                      type="number"
                      value={max}
                      onChange={handleMaxSelect}
                      placeholder="Max Price"
                    />
                  </div>
                  <button onClick={resetSearch} className="button is-info">
                    Reset Search
                  </button>
                </div>
              ) : null}
            </div>
          </MobileView>
        </>
      )}
      <motion.div layout className="columns is-multiline mb-2">
        {properties ? (
          filterProperties().map((property) => {
            return (
              <PropertyCard
                key={property.id}
                property={property}
                isLoggedIn={isLoggedIn}
                handleFavourite={handleFavourite}
                isFavourite={favourites.includes(property.id)}
              />
            );
          })
        ) : failedFetch ? (
          <p id="loadingText">
            Fetch failed, please view our property listings on{" "}
            <a
              target="_blank"
              href="https://espc.com/find-an-agent?view=list&soli=mackay+sinclair"
            >
              ESPC
            </a>
          </p>
        ) : (
          <>
            <BarLoader css={override} />
            <p id="loadingText">Fetching properties, please wait.</p>
          </>
        )}
      </motion.div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </div>
  );
}
